import { useSession } from "@core/hooks/useSession";
import {
  api,
  Thread,
  ThreadMessage,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import { getQueryKey as getThreadQueryKey } from "../../hooks/v3/useThread";

interface PostThreadMessageVariables {
  channelId: number;
  memberId: number;
  content: string;
  shouldArchive: boolean;
  attachmentType: string | null;
  attachmentUrl: string | null;
  attachmentAspectRatio: number | null;
  attachmentTitle: string | null;
  threadKey: string | null;
  policy: string | null;
  signature: string | null;
  skipOptimistic?: boolean;
  size?: number | null;
  threadsQueryKey?: string[];
}

const parseAttachmentDataByType = (variables: PostThreadMessageVariables) => {
  if (variables.attachmentType === "image") {
    return {
      image_url: variables.attachmentUrl.includes("filestack")
        ? `${variables.attachmentUrl}?policy=${variables.policy}&signature=${variables.signature}`
        : variables.attachmentUrl,
      aspect_ratio: variables.attachmentAspectRatio,
    };
  }
  if (variables.attachmentType === "pdf") {
    return {
      url: variables.attachmentUrl.includes("filestack")
        ? `${variables.attachmentUrl}?policy=${variables.policy}&signature=${variables.signature}`
        : variables.attachmentUrl,
      name: variables.attachmentTitle,
    };
  }

  return null;
};

const postThreadMessage = (
  channelId: number,
  memberId: number,
  content: string,
  shouldArchive: boolean,
  attachmentType: string | null,
  attachmentUrl: string | null,
  attachmentAspectRatio: number | null,
  attachmentTitle: string | null,
  attachmentSize: number | null,
  accessToken: string
) => {
  return api
    .post<ThreadMessage>(
      `/v3/clinician/chat/channel`,
      {
        channel_id: channelId,
        member_id: memberId,
        content,
        should_archive: shouldArchive ? 1 : 0,
        attachment_type: attachmentType,
        attachment_data: attachmentUrl,
        attachment_aspect_ratio: attachmentAspectRatio,
        attachment_size: attachmentSize,
        attachment_title: attachmentTitle,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then((res) => {
      const { data } = res;
      return data;
    })
    .catch(transformAPIError);
};

export const usePostThreadMessage = (): UseMutationResult<
  ThreadMessage,
  Error,
  PostThreadMessageVariables
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      return postThreadMessage(
        payload.channelId,
        payload.memberId,
        payload.content,
        payload.shouldArchive,
        payload.attachmentType,
        payload.attachmentUrl,
        payload.attachmentAspectRatio,
        payload.attachmentTitle,
        payload.size,
        accessToken
      );
    },
    {
      onMutate: async (variables) => {
        if (variables.skipOptimistic === true) {
          return null;
        }

        const threadKey =
          variables.threadKey ||
          getThreadQueryKey(variables.channelId, variables.memberId);
        await queryClient.cancelQueries(threadKey);
        const threadToUpdate = queryClient.getQueryData(threadKey);

        queryClient.setQueryData(threadKey, (thread: Thread) => ({
          ...thread,
          messages: [
            ...(thread?.messages || []),
            {
              id: 1,
              content: variables.content,
              sent_by_user_id: session.user.id,
              sent_by_user_type: "premium_clinicians",
              chat_channel_id: variables.channelId,
              created_at: new Date().toISOString(),
              avatar: null,
              avatar_url: null,
              automated_message_type: null,
              data: parseAttachmentDataByType(variables),
            },
          ],
        }));

        // optionally update a secondary threads list if the key to the list
        // is provided in the post request
        if (variables.threadsQueryKey) {
          const allThreadsToUpdate = queryClient.getQueryData(
            variables.threadsQueryKey
          );

          const clonedThreads = [...(allThreadsToUpdate as Thread[])];
          const indexOfChangedThread = clonedThreads.findIndex(
            (x) => x.id === variables.channelId
          );
          clonedThreads[indexOfChangedThread] = {
            ...clonedThreads[indexOfChangedThread],
            messages: [
              {
                id: 1,
                content: variables.content,
                sent_by_user_id: session.user.id,
                sent_by_user_type: "premium_clinicians",
                chat_channel_id: variables.channelId,
                created_at: new Date().toISOString(),
                avatar: null,
                avatar_url: null,
                automated_message_type: null,
                data: parseAttachmentDataByType(variables),
              },
            ],
          };

          queryClient.setQueryData(variables.threadsQueryKey, clonedThreads);
        }

        return { threadToUpdate };
      },
      onSuccess: (_response, variables) => {
        void queryClient.invalidateQueries(variables.threadsQueryKey);

        const threadKey = getThreadQueryKey(
          variables.channelId,
          variables.memberId
        );
        void queryClient.invalidateQueries(threadKey);
      },
    }
  );
};
