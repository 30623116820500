import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { format } from "date-fns";
import { useQuery, UseQueryResult } from "react-query";

const getQueryKey = (channelId: number): string =>
  `channel-whispers-${channelId}`;

export interface ChannelWhisper {
  id: number;
  chat_channel_id: number;
  content: string;
  created_by_user_id: number;
  created_by_user_email: string;
  created_at: string;
  day: string;
  time: string;
  is_whisper: boolean;
  is_event: false;
  // fields that will always be null or not present, only here for parity with ThreadMessage interface
  automated?: null;
  automated_label?: null;
  automated_message_type?: null;
  avatar?: null;
  avatar_url?: null;
  is_birthday?: null;
  data?: null;
  first_name?: null;
  reactions?: null;
  sent_by_user_id?: null;
  sent_by_user_type?: null;
  sent_by_user_email?: null;
  user_type?: null;
  buttons?: null;
  priority?: number;
  is_text_match?: boolean;
  is_name_match?: boolean;
  type?: string;
  cta_key?: string;
}

const getChatChannelWhispers = async ({
  channelId,
  accessToken,
}: {
  channelId: number;
  accessToken: string;
}) => {
  return api
    .get<ChannelWhisper[]>(`/chat/advocate/channel/${channelId}/whispers`, {
      headers: {
        authorization: accessToken,
      },
    })
    .then(({ data }) => {
      return data.map((event) => {
        return {
          ...event,
          day: format(new Date(event.created_at), `PP`),
          time: format(new Date(event.created_at), `p`),
          is_whisper: true,
          is_event: false,
        };
      });
    })
    .catch(transformAPIError);
};

export const useChatChannelWhispers = (
  channelId: number
): UseQueryResult<ChannelWhisper[], Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(
    getQueryKey(channelId),

    async () => {
      return getChatChannelWhispers({
        channelId,
        accessToken,
      });
    },
    {
      enabled: !!channelId && !!accessToken,
    }
  );
};
